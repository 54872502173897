import React, { Component } from 'react';
import styled from 'styled-components';
class pageHeader extends Component {
    render() {
const heading = this.props.heading;



        return (
            <PageHeaderWrap color={this.props.source}>

<h1>{heading}</h1>
<hr/>
         
                
            </PageHeaderWrap>
        );
    }
}

const PageHeaderWrap = styled.div`

width:100%;
background:url(${props =>props.source || 'https://livedemo00.template-help.com/wt_prod-2267/images/breadcrumbs-background-01.jpg'});
padding:140px 120px;
margin-top:-100px;
h1{
    font-family: 'Marvel', sans-serif;
    font-size:40px;
letter-spacing:2px;
    color:white;
    text-align:left;
    text-transform:uppercase;
    font-weight:bold;
}

hr{
    border:none;
    height:10px;
    margin-left:0;
    background: linear-gradient(220deg,#EC5252 -10%,#6E1A52 70%);
    width:15%;
    clip-path: polygon(0 0, 96% 0, 88% 100%, 0% 100%);
border-radius:10%;
}

`

export default pageHeader;
import React, {createRef } from 'react';
import { graphql} from 'gatsby';
import PageHeader from '../../util/pageHeader';
import Image from 'gatsby-image';
import styled from 'styled-components'
import { FiEdit, FiClock, FiChevronsLeft } from "react-icons/fi";
import Otherpost from './otherpost';
import { Link } from 'gatsby';
import { FacebookProvider, Comments } from 'react-facebook';

import Layout from '../layout'
import 'semantic-ui-css/semantic.min.css'
import {
  Ref,
  Rail,
  Sticky,
} from 'semantic-ui-react';

const blogTemplate = ({data}) => {

const {title, slug, id,  author, description:{description}, image, publishDate} = data.blog
let contextRef = createRef();



    return (
      <Layout>
        <BlogTempWrap>
         
           <PageHeader heading={title}  source="https://livedemo00.template-help.com/wt_prod-2267/images/breadcrumbs-background-01.jpg"/>
<div className="container-fluid py-5">

  <div className="container">

  <div className="row">
  <div className="blogbox my-5  col-md-9 ">
  <h1 className="p3">{title}</h1>
            <Image fluid={image.fluid} alt="single post" className="mainpostimg" />
            
            <hr />
            <p className="text-muted p3"><span><FiEdit/></span> {author} &nbsp; &nbsp; &nbsp; <span><FiClock/></span> {publishDate}</p>

             <p>{description}</p>
             <Link to="/blog"  rel="noopener noreferrer"><FiChevronsLeft/>Back</Link>
             </div>

             <div className="col-md-3 ">
             <Ref innerRef={contextRef} id="f">
             <Rail >
             <Sticky context={contextRef}   offset={25} >
             <Otherpost change={title}  />
             </Sticky>
             </Rail>
             </Ref>
             </div>
             </div>
             <FacebookProvider appId="3037432406330852">
        <Comments href="http:localhost:8000" />
      </FacebookProvider>


             </div>
            
</div>

            
        </BlogTempWrap>
        </Layout>
    );
};

export const query = graphql`

query($slug:String!){
  
    blog:contentfulBlog(slug:{eq:$slug}){
      
      
     contentful_id
      title
      author
      description
      {
        description
      }
      
      publishDate(formatString: "MMMM Do, YYYY ")
      image{
        
        fluid{
          
        ...GatsbyContentfulFluid_withWebp



        }
      }
      
    }
  }



`
const BlogTempWrap = styled.div`

background-color:#000000;

.ui.rail {

  z-index:0 !important;
}
@media (max-width:960px){

  .ui.rail {

    position:static !important;
    width:100%;
  }

h1{

  font-size:25px;
  text-transform:uppercase;
}

}

.container{

width:100%;

}

h1{

  margin-top:20px;
  color:white;
}
p{

  font-family: 'Roboto', sans-serif;
font-size:16px !important;
color:gray;
}
span{

  color: #24B8BD;
  font-weight:bold;
}

.mainpostimg{

width:100%;


}
a{
  color: #24B8BD;
  font-weight:bold;
}



`

export default blogTemplate;
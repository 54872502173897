import React from 'react';
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby';
import Image from 'gatsby-image';
import { Link } from 'gatsby';




const otherpost = (props) => {


    let color = props.change;

    console.log(color);
    
    const getOPosts = graphql`

query {
    posts: allContentfulBlog(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
            publishDate(formatString: "MMMM Do, YYYY ")
          title
          
          slug
          contentful_id
          image {
            fluid {
                ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

console.log(otherpost);

    return (
        <OtherpostWrap props= {color}>

          

<StaticQuery  query={ getOPosts} render={data =>{


    const posts = data.posts.edges;

    
return(<>
<div className="container ">
<div className="row" >
<div className="card" style={{width: "100%"}}>
        <div className="card-header bg-dark text-white">
         Other Blogs
        </div>
        <ul className="list-group list-group-flush">
{
posts.map(({node:item}, i)=>{

  if(item.title !== color){

return(


<>

<div key={i}>
  <Image  fluid={item.image.fluid} alt="single post" className="otherpostimg p2"/></div>
<div key={item.title}>
  <Link to={`/blogs/${item.slug}`} ><li className="list-group-item">{item.title}</li></Link>
  </div>
      

</>

)}

})
}
  </ul>
      </div> 
</div>
</div>
</>)

}}/>

    
      </OtherpostWrap>
    );
};

const OtherpostWrap = styled.div`

box-shadow: 0 2px 2px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.1);
    width:100% !important;


.card{

  background-color: rgb(255 255 255 / 6%);
  .list-group-item{

    background-color: rgb(255 255 255 / 1%) !important;

}
}

ul{

    display:grid;
    grid-template-columns:30% 70%;
    overflow:hidden;
    padding:10px;

    div{
         
        border-bottom:1px solid rgba(0,0,0,0.1);
margin-top:0.3rem;
    }

    li{

      margin-top:0.35rem;

    }

  
}

margin-top:50px;

.otherpostimg{

    padding:10px;
  
    width:100%;
    height:50px;
    border-radius:5px;
}

.container{

  position:sticky;

  top:50%;
}

@media(max-width:960px){

.card{
  display:grid;


}
  
}

`

export default otherpost;